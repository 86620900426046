<template>
  <div>
    <el-card>
      <div class="flex flex-align-center flex-wrap">
        <div class="flex flex-align-center margin-right-10 margin-bottom-10">
          <div class="keyLabel">测试时间：</div>
          <el-date-picker
            v-model="time"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-date-picker>
        </div>
        <el-button type="primary" class="margin-bottom-10" @click="currentPage=1,gettablelist()">查询</el-button>
      </div>
      <div class="margin-top-10">
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="" label="客户">
            <template slot-scope="scope">
              <div class="flex flex-align-center">
                <img :src="scope.row.WxHeadUrl||defaultHeader" style="width:50px;height:50px;margin-right:5px;border-radius:3px" alt="">
                <div style="width:0;flex:1">
                  <div class="ellipsis">
                    {{scope.row.WxNickname}}
                    <span v-if="scope.row.Name">({{scope.row.Name}})</span>
                  </div>
                  <div>
                    {{scope.row.Phone}}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="推荐商品">
            <template slot-scope="scope">
              <div class="flex flex-align-center flex-wrap">
                <el-popover placement="top" trigger="hover" v-for="(v,i) in scope.row.MemberTakeTestRecordProductInfoList" :key="i">
                  <div style="max-width:200px">
                    {{v.Name}}
                  </div>
                  <div slot="reference">
                    <img :src="v.ImgUrlComplete" style="width:50px;height:50px;margin-right:5px;border-radius:3px" alt="">
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="LastTestTime" label="最近一次测试时间"></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="details(scope.row)">测一测详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="margin-top-10" style="text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="detailShow" title="测一测详情" custom-class="dialog-body-paddingTop-10" @closed="activeName='1'">
      <div style="max-height:500px;overflow-y:auto">
        <div class="font-14" style="color:#606266FF">
          <div class="flex">
            <div class="keyLabel" style="width:150px;text-align:right;flex-shrink:0">测试人：</div>
            <div style="color:#409eff;line-height:1.5;cursor: pointer;width:400px" @click="toclient">
              <div class="ellipsis">
                {{rowmsg.WxNickname}}
                <span v-if="rowmsg.Name">({{rowmsg.Name}})</span>
              </div>
            </div>
          </div>
          <div class="flex margin-top-20">
            <div class="keyLabel" style="width:150px;text-align:right;flex-shrink:0">最近一次测试时间：</div>
            <div>
              {{rowmsg.LastTestTime}}
            </div>
          </div>
        </div>
        <el-tabs v-model="activeName" class="margin-top-20">
          <el-tab-pane label="商品属性" name="1"></el-tab-pane>
          <el-tab-pane label="推荐商品" name="2"></el-tab-pane>
          <el-tab-pane label="答题详情" name="3"></el-tab-pane>
        </el-tabs>
        <div v-if="activeName=='1'">
          <div class="font-14" style="color:#999999FF">
            根据答题情况，命中率最高的前10个商品属性
          </div>
          <div>
            <el-tag type="info" v-for="(v,i) in rowmsg.MemberTakeTestRecordProductAttributeInfoList" :key="i"
             class="infotag margin-top-10 margin-right-10" v-if="v.ProductAttributeValue">
              <span class="nameSpan" v-for="(x,y) in v.ProductAttributeValuelist" :key="y">{{x}}</span>
            </el-tag>
          </div>
        </div>
        <div v-if="activeName=='2'">
          <div class="font-14" style="color:#999999FF">
            根据命中的商品属性，匹配率最高的前5款商品
          </div>
          <div class="flex flex-align-center" style="margin-top:16px" v-for="(v,i) in rowmsg.MemberTakeTestRecordProductInfoList" :key="i">
            <img :src="v.ImgUrlComplete" style="width:60px;height:60px;margin-right:10px;border-radius:3px" alt="">
            <div class="font-14" style="color:#606266FF">
              {{v.Name}}
            </div>
          </div>
        </div>
        <div v-if="activeName=='3'">
          <div class="tableBorder flex" v-for="(v,i) in rowmsg.MemberTakeTestRecordDetailInfoList" :key="i">
            <div class="keyLabel" style="margin-right:3px">{{i+1}}.</div>
            <div>
              <div class="font-14 font-bold" style="color:#606266FF;line-height:1.5">{{v.TakeTestQuestionName}}</div>
              <div class="font-14" style="color:#606266FF;line-height:1.5">
                
                <span>
                  {{v.TakeTestQuestionOptionName}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  memberTakeTestRecordlist
} from "@/api/TurnTomySelf"
import config from "@/config"
import {
  nextDay
} from "@/utils"
export default {
  data () {
    return {
      time:[],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      defaultHeader:config.DEFAULT_HEADER,
      detailShow:false,
      rowmsg:{},
      activeName:'1',
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    toclient(){
      this.$router.push({
        name:'CustomerDetail',
        query:{
          Id:this.rowmsg.MemberId
        }
      })
    },
    details(row){
      // console.log(row)
      this.rowmsg = row
      this.detailShow = true
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          TakeTestId:this.$route.query.Id,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        }
        if(this.time&&this.time.length){
          data.StartTime = this.time[0]
          data.EndTime = nextDay(this.time[1],1)
        }
        let res = await memberTakeTestRecordlist(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results.map(v=>{
            v.MemberTakeTestRecordProductAttributeInfoList = v.MemberTakeTestRecordProductAttributeInfoList.map(x=>{
              if(x.ProductAttributeValue){
                x.ProductAttributeValuelist = x.ProductAttributeValue.split('|')
              }
              return x
            })
            return v
          })
          console.log(this.tablelist)
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }  
  }
}
</script>

<style lang='less' scoped>
.infotag{
  .nameSpan{
    font-size: 14px;
    color: #999999FF;
    &::after{
      content:'丨';
    }
    &:last-of-type{
      color: #606266FF !important;
      &::after{
        content:'';
      }
    }
  }
}
.tableBorder{
  
  border: 1px solid #EBEEF5;
  padding: 12px 15px;
  border-bottom: none;
  &:last-child{
    border-bottom: 1px solid #EBEEF5;
  }
}
</style>